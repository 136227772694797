import image1 from './images/1.jpeg'
import image2 from './images/2.jpeg'
import image3 from './images/3.jpeg'
import image4 from './images/4.jpeg'
import image5 from './images/5.jpeg'
import image6 from './images/6.jpeg'
import image8 from './images/8.jpeg'
import image9 from './images/9.jpeg'
import image10 from './images/10.jpeg'
import image11 from './images/11.jpeg'
import image15 from './images/15.jpeg'
import image17 from './images/17.jpeg'
import image18 from './images/18.jpeg'
import image19 from './images/19.jpeg'
import image20 from './images/20.jpeg'
import image21 from './images/21.jpeg'
import image22 from './images/22.jpeg'
import image23 from './images/23.jpeg'
import image24 from './images/24.jpeg'
import image25 from './images/25.jpeg'
import image28 from './images/28.jpeg'
import image29 from './images/29.jpeg'
import image30 from './images/30.jpeg'
import image31 from './images/31.jpeg'
import image32 from './images/32.jpeg'
import image33 from './images/33.jpeg'
import image34 from './images/34.jpeg'
import image35 from './images/35.jpeg'
import image36 from './images/36.jpeg'
import image37 from './images/37.jpeg'
import image38 from './images/38.jpeg'
import image40 from './images/40.jpeg'
import image41 from './images/41.jpeg'
import image42 from './images/42.jpeg'
import image43 from './images/43.jpeg'
import image44 from './images/44.jpeg'
import image45 from './images/45.jpeg'
import image46 from './images/46.jpeg'
import image47 from './images/47.jpeg'
import image48 from './images/48.jpeg'
import image49 from './images/49.jpeg'
import image50 from './images/50.jpeg'


//Assgin images to variable before exporting
export default [image1, image2, image3, image4, image5, image6, image8, image9, image10, image11, image15, image17, image18, image19, image20, image21, image22, image23, image24, image25, image28, image29, image30, image31, image32, image33, image34, image35, image36, image37, image38, image40, image41, image42, image43, image44, image45, image46, image47, image48, image49, image50]