import StartApp from "./StartApp";


function App() {
  return (
    <StartApp/>
  );
}

export default App;
